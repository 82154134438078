<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <div class="title title-top">
        <img src="./../image/hero.jpg" class="bg"/>
        <b>Buy skins</b>
      </div>
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="top">
            <div class="filters">
              <div class="filters-wrapper">
                <div class="filters-block filters-price">
                  <div class="desc small">
                    <b>PRICE</b>
                  </div>
                  <div class="val-container">
                    <div class="val min">
                      <input type="number" v-model="barMinValue"/>
                      <div class="desc">{{$parent.currency}}</div>
                    </div>
                    <div class="val max">
                      <input type="number" v-model="barMaxValue"/>
                      <div class="desc">{{$parent.currency}}</div>
                    </div>
                  </div>
                </div>
                <div :class="['filters-block filters-quality', {'active': heroFiltrIsOpen}]" v-if="categoryOptions && categoryOptions.length">
                  <div class="desc small" @click="heroFiltrIsOpen = !heroFiltrIsOpen">
                    <b>HERO</b>
                  </div>
                  <transition name="slide">
                    <div class="laebel-list" v-if="heroFiltrIsOpen">
                      <label class="chekbox-label" v-for="(item, i) in heroOptions" :key="i">
                        <div class="chekbox-label-wrapper">
                          <input type="radio" :value="item" v-model="hero"/>
                          <div class="checkbox"></div>
                          <span class="title">{{ item }}</span>
                        </div>
                      </label>
                    </div>
                  </transition>
                </div>
                <div :class="['filters-block filters-quality', {'active': typefFiltrIsOpen}]" v-if="categoryOptions && categoryOptions.length">
                  <div class="desc small" @click="typefFiltrIsOpen = !typefFiltrIsOpen">
                    <b>TYPE</b>
                  </div>
                  <transition name="slide">
                    <div class="laebel-list" v-if="typefFiltrIsOpen">
                      <label class="chekbox-label" v-for="(item, i) in categoryOptions" :key="i">
                        <div class="chekbox-label-wrapper">
                          <input type="radio" :value="item" v-model="category"/>
                          <div class="checkbox"></div>
                          <span class="title">{{ item }}</span>
                        </div>
                      </label>
                    </div>
                  </transition>
                </div>
                <div :class="['filters-block filters-quality', {'active': qualityfFiltrIsOpen}]" v-if="qualityOptions && qualityOptions.length">
                  <div class="desc small" @click="qualityfFiltrIsOpen = !qualityfFiltrIsOpen">
                    <b>QUALITY</b>
                  </div>
                  <transition name="slide">
                    <div class="laebel-list" v-if="qualityfFiltrIsOpen">
                      <label class="chekbox-label" v-for="(item, i) in qualityOptions" :key="i">
                        <div class="chekbox-label-wrapper">
                          <input type="radio" :value="item" v-model="quality"/>
                          <div class="checkbox"></div>
                          <span class="title">{{ item }}</span>
                        </div>
                      </label>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </div>
          <div class="products-container">
            <div class="sort-bar">
              <div class="desc link" @click="resetFilters">{{ $t('reset filters') }}</div>
              <div class="desc small">{{ totalProducts }} {{ $t('items') }}</div>
              <div class="select-container" v-if="sortOptions && sortOptions.length">
                <Select2 class="select2 single" v-model="sort" @select="selectSort($event)" :options="sortOptions" :placeholder="$t('Sort')" />
              </div>
            </div>
            <transition name="fade">
              <div class="title" v-if="productList && !productList.length && productListLoaded">
                {{ $t('No products!') }}
              </div>
            </transition>
            <transition name="fade">
              <div class="list products" v-if="productList && productList.length">
                <div class="item" v-for="(item, i) in productList" :key="i">
                  <ProductCard :item="item" :addToCartChosenItem="addToCartChosenItem" :sliderIsClickble="true" @goToProduct="goToProduct" :currency="currency" @addToCart="addToCart"/>
                </div>
              </div>
            </transition>
          </div>
          <pagination :per-page="18" v-if="totalProducts" v-model="activePage" :records="totalProducts" @paginate="changePage"/>
        </div>
      </section>
    </div>
  </main>
</template>
<script>
import Pagination from "vue-pagination-2";
import ProductCard from "../components/ProductCard.vue";
import Select2 from "vue3-select2-component";

export default {
  name: "ProductListPage",
  props: ["addToCartChosenItem", "currency", "currencyCode"],
  components: {
    Pagination,
    ProductCard,
    Select2
  },
  data: function () {
    return {
      imgDomain: "",
      sort: "desc",
      category: '',
      quality: '',
      hero: '',
      activePage: 1,
      totalProducts: null,
      categoryOptions: [],
      qualityOptions: [],
      heroOptions: [],
      barMinValue: 0,
      barMaxValue: 200000,
      productList: {},
      productListLoaded: false,
      typeListIsVisible: true,
      qualityListIsVisible: false,
      priceListIsVisible: true,
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        },
        {
          id: 'asc',
          text: 'Lowest price first'
        }
      ],
      qualityfFiltrIsOpen: false,
      typefFiltrIsOpen: false,
      heroFiltrIsOpen: false
    };
  },
  watch: {
    currencyCode: function () {
      this.filterProducts();
    },
    currency: function () {
      this.filterProducts();
    },
    barMinValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    barMaxValue: function () {
      this.activePage = 1;
      this.filterProducts();
    },
    hero: function() {
      this.activePage = 1;
      this.filterProducts();
    },
    sort() {
      this.activePage = 1;
      this.filterProducts();
    },
    quality() {
      this.activePage = 1;
      this.filterProducts();
    },
    category() {
      this.activePage = 1;
      this.filterProducts();
    },
    $route() {
      this.activePage = 1;
      this.checkRouteParams();
    },
  },
  mounted() {
    this.checkRouteParams();
    this.getCategoryOptions();
    this.getQualityOptions();
  },
  methods: {
    selectCategory(event) {
      this.category = event.text;
    },
    selectSort(event) {
      this.sort = event.id;
    },
    checkRouteParams() {
      if (this.$route.params.type && this.$route.params.type != "all") {
        this.category = [];
        this.quality =  this.$route.params.type;
      } else if (this.$route.params.type && this.$route.params.type === "all") {
        this.category = [];
        this.quality = [];
      }
      this.activePage = 1;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = [];
      this.category = [];
      this.hero = [];
      this.barMinValue = 0;
      this.barMaxValue = 1200;
    },
    filterProducts() {
      let addCategory = "";
      if (this.category != "All types") {
        addCategory = "type=" + this.category;
      } else {
        addCategory = "type=";
      }

      let addQuality = "";
      if (this.quality != "All quality") {
        addQuality = "&quality=" + this.quality;
      } else {
        addQuality = "";
      }
      let addPrice = "&price_from=" + this.barMinValue + "&price_till=" + this.barMaxValue;

      let addSort = "&sort=" + this.sort;

      let addPage = "&page=" + this.activePage;

      let addHero = '';
      if (this.hero != "All heroes") {
        addHero = '&heroes=' + this.hero
      } else {
        addHero = '';
      }
      this.$http
      .get(process.env.VUE_APP_API + "items/list?" + addCategory + addQuality + addPrice + addHero + addSort + addPage + "&limit=20" +  '&currency=' + this.currencyCode)
      .then((res) => {
        console.log(res);
        this.productList = res.data.payload;
        this.totalProducts = res.data.meta.total;
        this.pages = res.data.meta.total;
        this.productListLoaded = true;
      })
      .catch((res) => {
        if (res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      });
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newCategories = res.data.payload.types;
          newCategories.unshift("All types");
          this.newCategories = newCategories;
          this.categoryOptions = newCategories;

        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    getQualityOptions() {
      this.$http
        .get(process.env.VUE_APP_API + "items/filter-data")
        .then((res) => {
          let newQuality = res.data.payload.qualities;
          newQuality.unshift("All quality");
          this.qualityOptions = newQuality;

          let newHero = res.data.payload.heroes;
          newHero.unshift("All heroes");
          this.heroOptions = newHero;

        })
        .catch((res) => {
          if (res.response.status === 401) {
            this.$parent.openSignInModal();
          }
        });
    },
    addToCart(item, id) {
      this.$emit("addToCart", item, id);
    },
    goToProduct(item) {
      this.$emit("goToProduct", item);
    },
  },
  
};
</script>
