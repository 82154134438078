<template>
  <div class="slider-container">
    <slick ref="slick" :options="slickOptions" class="slider products" @swipe="handleSwipe">
      <div class="item" v-for="(item, index) in products" :key="index">
        <ProductCard :item="item" :sliderIsClickble="sliderIsClickble" @goToProduct="goToProduct" :currency="currency" />
      </div>
    </slick>
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard.vue'
import Slick from 'vue-slick';

export default {
  name: 'SlickItem',
  props: ['currency', 'products'],
  components: {
    Slick,
    ProductCard
  },
  data: function() {
    return {
      imgDomain: '',
      sliderIsClickble: true,
      slickOptions: {
        slidesToShow: 3,
        arrows: true,
        variableWidth: true,
        infinite: false,
        centerMode: true,
        initialSlide: 1,
        dots: false,
        lazyLoad: 'ondemand',
        responsive: [
          {
            breakpoint: 1201,
            settings: {
              slidesToShow: 3,
            }
          },
          {
            breakpoint: 901,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 769,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 601,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 450,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
      },
    }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    global.jQuery('.slick-slider').on('beforeChange', function(event, slick, currentSlide, nextSlide){
      if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
        console.log(1)
        global.jQuery(this).find('.slick-current').prev().prev().addClass('before-current');
        global.jQuery(this).find('.slick-current').prev().prev().siblings().removeClass('before-current');
      } else if (nextSlide > currentSlide || (currentSlide === (slick.$slides.length - 1) && nextSlide === 0 )) {
        global.jQuery(this).find('.slick-current').addClass('before-current');
        global.jQuery(this).find('.slick-current').siblings().removeClass('before-current');
      } else {
        global.jQuery(this).find('.slick-current').prev().prev().addClass('before-current');
        global.jQuery(this).find('.slick-current').prev().prev().siblings().removeClass('before-current');
      }
      global.jQuery(this).find('.slick-slide').first().addClass('first');
    });
  },
  methods: {
    handleSwipe() {
      let self = this;
      self.sliderIsClickble = false;
      setTimeout(()=> {
        self.sliderIsClickble = true;
      }, 300)
    },
    goToProduct(item) {
      this.$emit('goToProduct', item)
    }
  }
}
</script>