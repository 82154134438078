<template>
  <main class="main product-page page-inside">
    <div class="main-wrapper">
      <div class="title title-top">
        <img src="./../image/hero.jpg" class="bg"/>
      </div>
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <transition name="fade">
            <div class="product-preview" v-if="$parent.productPageItem">
              <div class="preview-container">
                <div class="preview-wrapper">
                  <div class="preview">
                    <transition name="fade">
                      <span class="title" v-if="$parent.addToCartChosenItem == $parent.productPageItem.id">Added</span>
                    </transition>
                    <img :src="$parent.imgDomain + $parent.productPageItem.img_url" class="img"/>
                  </div>
                  <div class="text">
                    <div class="desc">{{ $parent.productPageItem.type }}</div>
                    <div class="title">{{ $parent.productPageItem.title }}</div>
                    <div class="desc">
                      {{ $parent.productPageItem.quality }}
                    </div>
                    <div class="title price">
                      {{ $parent.productPageItem.price }}
                      <span class="currency">{{ $parent.currency }}</span>
                    </div>
                    <button class="button orange" @click="$parent.addToCart($parent.productPageItem, $parent.productPageItem.id)">
                      <span>{{ $t("Add to cart") }}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>
    </div>
  </main>
</template>
<script>

export default {
  name: "ProductPage",
  props: ["addToCartChosenItem", "currencyCode"],
  components: {},
  data: function () {
    return {};
  },
  created() {},
  mounted() {
    this.$parent.getProductPageContent(this.$route.params.id);
  },
  methods: {
    addToCart(item, id) {
      this.$parent.addToCart(item, id);
    },
    goToProduct(item) {
      this.$parent.goToProduct(item);
    },
  },
  watch: {
    currencyCode() {
      this.$parent.getProductPageContent(this.$route.params.id);
    }
  },
  computed: {
  }
};
</script>
