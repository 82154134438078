<template>
  <div class="modal sign-modal">
    <div class="overlay" @click="$emit('closeSignInModal')"></div>
    <div class="wrapper">
      <div class="container">
        <div class="left">
          <div class="title normal">{{ $t('Don\'t have an account?') }}</div>
          <div class="title">
            <b>{{ $t('Join Us Today!') }}</b>
          </div>
          <button @click="$parent.openSignUpModal" class="button">{{ $t('Sign UP') }}</button>
        </div>
        <div class="form">
          <div class="form-wrapper">
            <img class="close" @click="$emit('closeSignInModal')" src="./../image/close.svg" />
            <div :class="['form-fields login-content', { hidden: !loginContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title">
                  <b>{{ $t("Sign In") }}</b>
                </div>
                <label>
                  <div class="desc small">{{ $t('E-mail:') }}</div>
                  <input type="email" v-model="email" />
                </label>
                <label>
                  <div class="desc small">{{ $t('Password:') }}</div>
                  <input type="password" v-model="pass" />
                </label>
                <div class="cta-container">
                  <button class="button orange" @click="submitLogin">
                    <span>{{ $t("Sign in with email") }}</span>
                  </button>
                  <div class="link desc" @click="switchToforgotPass">
                    <b>{{ $t("Forgot password?") }}</b>
                  </div>
                </div>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
            <div :class="['form-fields forgot-pass-content', { hidden: !forgotPassContentVisible }]">
              <div class="form-fields-wrapper">
                <div class="title">{{ $t("Forgot password") }}?</div>
                <label>
                  <div class="desc">{{ $t("Email") }}:</div>
                  <input type="email" :placeholder="$t('Email')" v-model="email" />
                </label>
                <div class="cta-container">
                  <button class="button" @click="submitForgotPass">
                    <span>{{ $t("Recover") }}</span>
                  </button>
                </div>
                <transition name="fade">
                  <div v-if="$parent.successMessage" class="desc green">
                    {{ $parent.successMessage }}
                  </div>
                </transition>
                <transition name="slide">
                  <div v-if="$parent.error" class="error-desc desc red">
                    {{ $parent.error }}
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SignInModal",
  props: {
    isVerified: Boolean
  },
  components: {},
  data: function () {
    return {
      loginContentVisible: true,
      forgotPassContentVisible: false,
      email: "",
      pass: "",
    };
  },
  mounted() {},
  methods: {
    switchToforgotPass() {
      this.$parent.clearError();
      setTimeout(() => {
        this.loginContentVisible = false;
        this.forgotPassContentVisible = true;
      }, 100);
    },
    switchToLogin() {
      this.$parent.clearError();
      setTimeout(() => {
        this.forgotPassContentVisible = false;
        this.loginContentVisible = true;
      }, 100);
    },
    submitLogin() {
      let loginData = {
        username: this.email,
        password: this.pass,
      };
      this.$emit("login", loginData);
    },
    submitForgotPass() {
      let data = {
        email: this.email,
      };
      this.$emit("forgotPass", data);
    },
  },
};
</script>
